import React from 'react';


let app = "MyTree";
let rel = "noopener noreferrer";
let target = "_blank";

export const URLS = {
	home: "https://www.itreetools.org/",
	viable: "https://database.itreetools.org/#/viableLocations",
	email: "mailto:info@itreetools.org?subject=Intl Funding",
	eula: "https://help.itreetools.org/eula?embed=true",
	ourtrees: "https://ourtrees.itreetools.org",
	mytree: "https://mytree.itreetools.org",
	forestService: "https://www.fs.usda.gov/",
	iTreeTools: "https://www.itreetools.org/",
};

export const ANCHORS = {
	home: <a href={URLS.home} rel={rel} target={target}>www.itreetools.org</a>,
	eula: <a href={URLS.eula} rel={rel} target={target}>EULA</a>,
	viability: <a href={URLS.viable} rel={rel} target={target}>supported by i-Tree<sup>&reg;</sup></a>,
	ourtrees: <a href={URLS.ourtrees} rel={rel} target={target}>OurTrees</a>,
	email: <a href={URLS.email}>Contact us</a>,
};

export const NAVBAR = {
	app: app,
	tagline:
		<span>
			<span className={"d-none d-lg-inline"}>A tool for assessing individual trees.</span>
			<span className={"d-none d-md-inline d-lg-none"}>For assessing individual trees.</span>
			<span className={"d-none d-sm-inline d-md-none"}>For assessing trees.</span>
			<span className={"d-inline d-sm-none"}>Individual trees.</span>
		</span>,
	taglineClasses: "tagline",
	appNameClasses: "app-name dark-blue",
	navbarClasses: "green-gradient",
};

export const LANDING = {
	app: app,
	headline: "Discover the benefits of your trees!",
	intro:
		<span>
			Tell us about your tree and we'll estimate the{" "}
			<span className={'fw-bold green'}>
				carbon dioxide
			</span>
			{" "}and{" "}
			<span className={'fw-bold brown'}>
				air pollution
			</span>
			{" "}it removes plus{" "}
			<span className={'fw-bold blue'}>
				stormwater
			</span>
			{" "}impacts.
		</span>,
	description: <small className={"fst-italic"}>
					MyTree is a tool for assessing individual trees.<br/>
					Check out {ANCHORS.ourtrees} for canopy cover benefits.
				</small>,
	locationWarning: <small className={"fst-italic"}>
						In locations not yet{" "}
						{ANCHORS.viability},<br/>
						US national averages will be used to<br/>
						estimate tree benefits.<br/>
						{ANCHORS.email} and let's explore funding opportunities<br/>
						to enable it for your country!
					</small>,
	eula: <small className={"fst-italic"}>
			Use of this tool indicates you accept our{" "}
			{/*FIXME: Move the EULA to modal*/}
			{ANCHORS.eula}
		</small>
};


export const LOCATION = {
	headline: "Where is your tree?",
	navButtonParameters: {
		path: "/tree",
		text: "Next, describe your tree",
	},
	subAddressbarText: "Fine-tune the location of your tree by tapping a spot on the map below.",
};

export const REPORT = {
	headline: "MyTree Benefits",
	subheading: ["For this year.", "Over 20 years."],
	total: ["Estimated this year:", "Expected over 20 years:"],
	upperBar: ["Annual values:", null],
	lowerBar: ["Not an annual value:", null],
	bottom: {
		blurb: <p>Benefit estimates are based on USDA Forest Service research
			and are meant for guidance only. Visit {ANCHORS.home} to learn more.
		</p>,
		valueDeclaration: <span data-html2canvas-ignore={"true"}>
			See the Project Menu for currency conversions.
		</span>,
		abbreviations: <span>Abbreviations:
			<ul className={"list-unstyled"}>
				<li>CO<sub>2</sub> = Carbon dioxide</li>
				<li>PM<sub>2.5</sub> = Particulate matter 2.5 microns or less</li>
				<li>lbs = Pounds</li>
				<li>kg = Kilograms</li>
				<li>gal = Gallons</li>
				<li>L = Liters</li>
				<li>oz = Ounces</li>
				<li>g = Grams</li>
				<li>kWh = Kilowatt hour</li>
				<li>MMBtu = Millions of British thermal units</li>
			</ul></span>,
		footnotes: [
			<span>
				For large trees sequestration is overtaken by CO<sub>2</sub>{" "}
				loss with decay/maintenance.
			</span>,
			<span>
				CO<sub>2</sub> equivalent is estimated by calculating how much
				atmospheric CO<sub>2</sub> is taken in by trees to provide the
				carbon stored in the tissues of individual trees.
			</span>,
			<span>
				Positive energy values indicate savings or reduced emissions.
				Negative values indicate increased usage or emissions.
				Electricity used for cooling and heating and fuels like
				natural gas or oil used for heating, based on typical usage
				for the selected location.
			</span>,
			<span>
				Not an annual amount or value.
			</span>,
		],
		poweredBy: "Engine",
	},
};
