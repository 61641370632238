import React, {memo, useState} from "react";

import {
	Button,
	Col,
	Row,
	Label,
	FormFeedback,
	FormGroup,
	Input,
	FormText,
	Card,
} from "reactstrap";
import {FontAwesomeIcon as FAI} from "@fortawesome/react-fontawesome";

import {
	InfoModalButton,
	NavButton,
	RadioButtonGroup,
} from "./Buttons";
import {FormField} from "./FormField";
import {ToggleSwitch} from "./ToggleSwitch";
import {SpeciesSelector} from "./SpeciesSelector";

import "../../stylesheets/species-selector.scss";

import {CONDITIONS} from "../../utilities/resources";

 import {Ash} from "../../img/leaves/ash";
 import {Basswood} from "../../img/leaves/basswood";
 import {Locust} from "../../img/leaves/locust";
 import {Maple} from "../../img/leaves/maple";
 import {Oak} from "../../img/leaves/oak";
 import {Palm} from "../../img/leaves/palm";
 import {Pear} from "../../img/leaves/pear";
 import {Pine} from "../../img/leaves/pine";
 import {Sweetgum} from "../../img/leaves/sweetgum";
 import {Sycamore} from "../../img/leaves/sycamore";


export const AddressField = memo(
	(
		{
			address,
			longitude,
			latitude,
		}
	) => {
		return (
			<Row>
				<Col
					xs={{size: 10}}
					sm={{offset: 1}}
					md={{size: 7, offset: 2}}
					lg={{size: 5, offset: 3}}
				>
					<Label className={"required"}>
						Location
					</Label>
					<Card className={"px-3 py-1 bg-light"}>
						<div className={"d-flex flex-column"}>
							<small>{address}</small>
							<small>Lat: {latitude.toFixed(5)},
								Lng: {longitude.toFixed(5)}</small>
						</div>
					</Card>
				</Col>
				<Col
					className={"d-flex flex-column justify-content-end"}
					xs={{size: 1}}
				>
					<NavButton
						className={"square round"}
						color={"success"}
						path={"/location"}
						text={""}
						title={"Edit location"}
						leftIcon={"pen"}
					/>
					<InfoModalButton
						subject={"address"}
					/>
				</Col>
			</Row>
		)
	}
);


export const GroupField = memo(
	(
		{
			group,
			updateProject,
		}
	) => {
		let updateGroup = (value) => {
			updateProject(
				{project: {group: value}}
			);
		};
		let label =
			<span>Project / Group name<span className={"d-none d-sm-inline"}> to search for on the MyTree Map</span></span>;

		return (
			<Row>
				<Col xs={{size: 10}}>
					<FormField
						classList={"text-nowrap"}
						feedback={
							"Please select the health" +
							" condition of your tree."
						}
						onChange={updateGroup}
						id={"group"}
						label={label}
						maxLength={"100"}
						onFocus={(e) => e.currentTarget.select()}
						type={"text"}
						value={group}
					/>
				</Col>
				<Col
					className={"d-flex justify-content-end align-items-end"}
					xs={{size: 2}}
				>
					<FormGroup>
						<InfoModalButton
							subject={"group"}
						/>
					</FormGroup>
				</Col>
			</Row>
		)
	}
);


export const ConditionField = memo(
	(
		{
			condition,
			updateProject,
		}
	) => {
		let updateCondition = (target) => {
			target.classList.remove("is_invalid");
			updateProject(
				{tree: {condition: target.value}}
			);
		};
		return (
			<Row className={"mt-2"}>
				<Col
					xs={{size: 10}}
					sm={{size: 6, offset: 1}}
					md={{size: 4, offset: 2}}
					lg={{size: 3, offset: 3}}
				>
					<Label
						className={"required"}
						for={"condition"}
					>
						Tree Condition
					</Label>
					<Input
						id={"condition"}
						type={"select"}
						value={condition}
						onChange={
							(e) => updateCondition(e.currentTarget)
						}
					>
						<option
							disabled
							value={"-1"}
						>
							Select a condition
						</option>
						{CONDITIONS.map((c, i) => {
							return (
								<option
									key={c}
									value={i}
								>
									{c}
								</option>
							)
						})}
					</Input>
					<FormFeedback>
						Please select a condition.
					</FormFeedback>
				</Col>
				<Col
					className={"d-flex align-items-end"}
					xs={{size: 1}}
					sm={{offset: 4}}
					md={{offset: 3}}
					lg={{offset: 2}}
				>
					<InfoModalButton
						subject={"condition"}
					/>
				</Col>
			</Row>
		)
	}
);


export const DiameterField = memo(
	(
		{
			measurementType,
			diameter,
			unitType,
			updateProject
		}
	) => {
		let unit = unitType ? "in." : "cm.";
		let [max, min, metric, pi, step] = [999, 1, 2.54, Math.PI, 0.1];

		diameter = parseFloat(diameter);
		if (measurementType === false) diameter = diameter * pi;
		if (unitType === false) diameter = diameter * metric;
		diameter = diameter.toFixed(1);

		diameter = !diameter || isNaN(diameter) ? "" : parseFloat(diameter);

		if (!unitType && measurementType) {
			[max, min] = [(max * metric), metric];
		} else if (unitType && !measurementType) {
			[max, min] = [(pi * max), pi];
		} else if (!unitType && !measurementType) {
			[max, min] = [(pi * (max * metric)), (pi * metric)];
		}
		min = min.toFixed(1);
		max = max.toFixed(1);

		// Parse into floats to satisfy NumericInput prop requirements.
		min = parseFloat(min);
		max = parseFloat(max);

		let updateMeasurementType = () => {
			updateProject(
				{tree: {measurementType: !measurementType}}
			)
		};

		let updateDiameter = (target) => {
			target.classList.remove("is-invalid");
			let value = target.value;

			if (unitType === false) value = value / metric;
			if (measurementType === false) value = value / pi;

			updateProject(
				{tree: {diameter: value}}
			)
		};

		return (
			<React.Fragment>
				<Row className={"mt-2"}>
					<Col
						xs={{size: 10}}
						sm={{size: 6, offset: 1}}
						md={{size: 4, offset: 2}}
						lg={{size: 3, offset: 3}}
					>
						<Label
							className={"text-nowrap required"}
							for={"diameter"}
						>
							{`Trunk Size (${unit})`}
						</Label>
						<input
							id={"diameter"}
							className={"form-control"}
							min={min}
							max={max}
							step={step}
							type={"number"}
							value={diameter}
							onFocus={
								(e) => e.target.select()
							}
							onChange={
								(e) => updateDiameter(e.currentTarget)
							}
						/>
						<FormFeedback
							className={"text-nowrap"}
						>
							{`Please enter a number between ${min} and ${max}`}
						</FormFeedback>
					</Col>
					<Col
						className={"d-flex align-items-end"}
						xs={{size: 1}}
						sm={{offset: 4}}
						md={{offset: 3}}
						lg={{offset: 2}}
					>
						<InfoModalButton
							subject={"diameter"}
						/>
					</Col>
				</Row>
				<Row>
					<Col
						xs={{size: 8}}
						sm={{size: 6, offset: 1}}
						md={{size: 4, offset: 2}}
						lg={{size: 3, offset: 3}}
						xl={{size: 3, offset: 3}}
					>
						<ToggleSwitch
							className={"mt-1"}
							updateField={updateMeasurementType}
							id={"measurementType"}
							value={measurementType}
							text={{left: "Diameter", right: "Circumference"}}
							title={"Toggle between measuring trunk diameter or circumference."}
						/>
					</Col>
				</Row>
			</React.Fragment>
		)
	}
);


export const ExposureField = memo(
	(
		{
			exposure,
			updateProject,
		}
	) => {
		let exposures = [
			{text: "Full"},
			{text: "Partial"},
			{text: "Shade"},
		];

		let updateExposure = (value) => {
			updateProject(
				{tree: {exposure: value}}
			)
		};

		return (
			<Row className={"mt-2"}>
				<Col
					xs={{size: 10}}
					sm={{size: 8, offset: 1}}
					md={{size: 6, offset: 2}}
					lg={{size: 4, offset: 3}}
				>
					<Label
						className={"text-nowrap required"}
						for={"exposure"}
					>
						Sun Exposure
					</Label>
					<RadioButtonGroup
						onRadioButtonClick={updateExposure}
						id={"proximity"}
						selected={exposure}
						settings={exposures}
						title={"Estimate how much sunlight your tree's crown receives."}
					/>
				</Col>
				<Col
					className={"d-flex align-items-end"}
					xs={{size: 1}}
					sm={{offset: 2}}
					md={{offset: 1}}
				>
					<InfoModalButton
						subject={"exposure"}
					/>
				</Col>
			</Row>
		)
	}
);


export const SaveButton = memo(
	(
		{
			text,
			validateInputs,
		}
	) => {
		let saveButtonValidateInputs = (e) => {
			validateInputs(e, "/dashboard");
		};

		let rightIcon = <FAI icon={"arrow-right"} className={"ms-1"}/>;

		return (
			<Row className={"my-3"}>
				<Col
					className={"text-center"}
				>
					<Button
						className={"round"}
						color={"primary"}
						onClick={saveButtonValidateInputs}
						title={"Save this tree and continue."}
					>
						{text} {rightIcon}
					</Button>
				</Col>
			</Row>
		)
	}
);


export const SpeciesField = (
	{
		tree,
		updateProject,
	}
) => {
	let updateNamingType = () => {
		updateProject(
			{tree: {namingType: !tree.namingType}}
		);
	};

	return (
		<React.Fragment>
			<Row>
				<Col
					xs={{size: 10}}
					sm={{offset: 1}}
					md={{size: 7, offset: 2}}
					lg={{size: 5, offset: 3}}
				>
					<div id={"species-form"}>
						<SpeciesSelector
							tree={tree}
							namingType={tree.namingType}
							updateProject={updateProject}
						/>
						<FormFeedback>
							A valid species must be selected from the list.
						</FormFeedback>
					</div>
				</Col>
				<Col
					className={"d-flex align-items-end"}
					xs={{size: 1}}
				>
					<InfoModalButton
						className={"mt-n2"}
						subject={"species"}
					/>
				</Col>
			</Row>
			<Row>
				<Col
					xs={{size: 8}}
					sm={{size: 6, offset: 1}}
					md={{size: 4, offset: 2}}
					lg={{size: 3, offset: 3}}
					xl={{size: 3, offset: 3}}
				>
					<ToggleSwitch
						className={"mt-1"}
						updateField={updateNamingType}
						id={"naming"}
						value={tree.namingType}
						text={{left: "Common", right: "Scientific"}}
						title={"Toggle between common and scientific tree names."}
					/>
				</Col>
				<Col className={"d-flex align-items-center"}>
					<a
						className={"small identilink text-underline"}
						href={"https://www.itreetools.org/tools/tree-identification-tools"}
						rel={"noopener noreferrer"}
						target={"_blank"}
					>
						Help with tree identification
					</a>
				</Col>
			</Row>
		</React.Fragment>
	)
};

export const Note = memo(
	(
		{
			note,
			updateProject,
		}
	) => {
		let updateNote = (value) => {
			updateProject(
				{project: {note: value}}
			);
		};

		return (
			<Row>
				<Col xs={{size: 10}}>
					<FormField
						id={"notes"}
						label={"Add a note or label for this tree"}
						maxLength={"25"}
						type={"text"}
						value={note || ""}
						onChange={updateNote}
					/>
				</Col>
				<Col
					className={"d-flex justify-content-end align-items-end"}
					xs={{size: 2}}
				>
					<InfoModalButton
						subject={"name"}
					/>
				</Col>
			</Row>
		)
	}
);

export const TypeField = memo(
	(
		{
			type,
			feedback,
			text,
			updateProject
		}
	) => {
		let types = [
			"None",
			"Existing",
			"New Planting",
			"Heritage",
			"Memorial",
			"Specimen",
			"Removal",
			"Planting Site",
			"Testing/Imaginary Tree",
		];

		return (
			<Row>
				<Col xs={{size: 10}}>
					<FormGroup>
						<Label
							className={"text-nowrap"}
							for={"treeType"}
						>
							Log the type of tree or planting site
						</Label>
						<Input
							id={"treeType"}
							type={"select"}
							value={type}
							onChange={
								(e) => updateProject(
									{tree: {type: e.currentTarget.value}}
								)
							}
						>
							<option
								disabled
								value={""}
							>
								Select a Type
							</option>
							{types.map((c, i) => {
								return (
									<option
										key={c}
										value={c}
									>
										{c}
									</option>
								)
							})}
						</Input>
						<FormFeedback>
							{feedback}
						</FormFeedback>
						{
							text ?
								<FormText>
									<em>{text}</em>
								</FormText>
								: null
						}
					</FormGroup>
				</Col>
				<Col
					className={"d-flex justify-content-end align-items-end"}
					xs={{size: 2}}
				>
					<FormGroup>
						<InfoModalButton
							subject={"type"}
						/>
					</FormGroup>
				</Col>
			</Row>
		)
	}
);

export const OptIn = memo(
	(
		{
			optIn,
			validateInputs,
			updateProject
		}
	) => {
		let updateOptIn = (e) => {
			updateProject(
				{hthc: {optIn: !optIn}},
				validateInputs(e, "/hthc", false)
			);
		};

		let url = "https://www.healthytreeshealthycitiesapp.org";

		return (
			<React.Fragment>
				<Row className={"mt-3"}>
					<Col xs={{size: 10}}>
						<Label>
							How stressed is your tree? Log more data below to
							discover.
							<br/>
							<span className={"text-center"}>
							<small className={"fst-italic"}>
								More details at The Nature Conservancy's{" "}
								<a
									href={url}
									target={"_blank"}
									rel={"noopener noreferrer"}
								>
									Healthy Trees, Healthy Cities Initiative.
								</a>
							</small>
						</span>
						</Label>
					</Col>
					<Col
						className={"mt-3 d-flex justify-content-end align-items-start"}
						xs={{size: 2}}
					>
						<FormGroup>
							<InfoModalButton
								subject={"hthc"}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					<Col
						xs={{size: 12}}
					>
						<NavButton
							className={"round"}
							outline
							path={"/hthc"}
							text={<small>Log health & pest data for The Nature
								Conservancy</small>}
							id={"optIn"}
							title={"Opt-in to helping the Healthy Trees, Healthy Cities Initiative"}
							onClick={updateOptIn}
						/>
					</Col>
				</Row>
			</React.Fragment>
		)
	}
);

export const TrillionTreesField = memo(
	(
		{
			trillionTrees,
			updateProject,
		}
	) => {
		const updateTrillionTrees = (value) => {
			if (value === trillionTrees) value = null;

			updateProject(
				{tree: {trillionTrees: value}}
			)
		};

		return (
			<Row>
				<Col
					xs={{size: 10}}
					sm={{size: 8}}
				>
					<Label
						className={"text-nowrap"}
						for={"trillionTrees"}
					>
						Is this part of the Trillion Trees campaign?
					</Label>
					<RadioButtonGroup
						className={"w-100"}
						onRadioButtonClick={updateTrillionTrees}
						id={"trillionTrees"}
						selected={trillionTrees}
						settings={
							[
								{text: "Yes", color: "success"},
								{text: "No", color: "success"},
							]
						}
						title={
							"Toggle between whether a your tree" +
							" was planted as part of the Trillion" +
							" Trees campaign, or not."
						}
					/>
				</Col>
				<Col
					className={"d-flex justify-content-end align-items-end"}
					xs={{size: 2}}
					sm={{size: 4}}
				>
					<InfoModalButton
						subject={"trillionTrees"}
					/>
				</Col>
			</Row>
		)
	}
);


let buttonParameters = [
	{
		image: Maple,
		label: "Maple",
		species: "AC",
		common: "Maple spp",
		scientific: "Acer",
	},
	{
		image: Oak,
		label: "Oak",
		species: "QU",
		common: "Oak spp",
		scientific: "Quercus",
	},
	{
		image: Sycamore,
		label: "Sycamore",
		species: "PL3",
		common: "Sycamore spp",
		scientific: "Platanus",
	},
	{
		image: Basswood,
		label: "Basswood",
		species: "TI",
		common: "Basswood spp",
		scientific: "Tilia",
	},
	{
		image: Locust,
		label: "Locust",
		species: "GL3",
		common: "Locust spp",
		scientific: "Gleditsia",
	},
	{
		image: Ash,
		label: "Ash",
		species: "FR",
		common: "Ash spp",
		scientific: "Fraxinus",
	},
	{
		image: Pear,
		label: "Pear",
		species: "PY",
		common: "Pear spp",
		scientific: "Pyrus",
	},
	{
		image: Sweetgum,
		label: "Sweetgum",
		species: "LI9",
		common: "Sweetgum spp",
		scientific: "Liquidambar",
	},
	{
		image: Pine,
		label: "Pine",
		species: "PI2",
		common: "Pine spp",
		scientific: "Pinus",
	},
	{
		image: Palm,
		label: "Palm",
		species: "AR10",
		common: "Arecastrum Palm spp",
		scientific: "Arecastrum",
	},
];

export const SpeciesShortCut = (
	{
		tree,
		showSpeciesShortcuts,
		updateProject,
	}
) => {
	let handleLeafButtonClick = (selectedTree, index) => {
		if (selectedTree.species === tree.species) {
			selectedTree.species = "";
			selectedTree.common = "";
			selectedTree.scientific = "";
		}

		var input = document.getElementById("species-input");
		var inputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, "value").set;
		var namingTypeSelection = tree.namingType ? selectedTree.common : selectedTree.scientific;
		inputValueSetter.call(input, namingTypeSelection);

		var inputEvent = new Event('input', {bubbles: true});
		input.dispatchEvent(inputEvent);

		updateProject({tree: selectedTree});
	};

	const [showLeafImages, setShowLeafImages] = useState(false);

	function updateButtonsToLeaves() {
		setShowLeafImages(true);
	}

	function updateButtonsToLabels() {
		setShowLeafImages(false);
	}

	return (
		<Row className={`mt-1 mb-${showSpeciesShortcuts ? "2" : "0"}`}>
			<Col
				xs={{size: 10}}
				sm={{size: 7, offset: 1}}
				md={{size: 5, offset: 2}}
				lg={{size: 4, offset: 3}}
			>
				<Label
					className={"required"}
					for={"species-input"}
				>
					Tree Species <span className={"text-danger"}>
					{showSpeciesShortcuts ?
						<span id={"leaf-button-name-selectors"}>(Stumped? Try a
							<button type='button' onClick={updateButtonsToLabels}> Name </button>
							 or
							<button type='button' onClick={updateButtonsToLeaves}> Icon </button>
							or type to search)
						</span>
						: "(Type to search)"}
				</span>
				</Label>
				{showSpeciesShortcuts ?
					<div className={"leaf-button-container"}>
						{
							buttonParameters.map(
								(
									{
										species,
										common,
										scientific,
										image,
										label
									},
									index
								) => {
									return (
										<button
											className={`leaf-button${species === tree.species ? " active" : ""}`}
											key={species}
											title={common}
											type={"button"}
											value={index}
											onClick={
												() => handleLeafButtonClick({
													species,
													common: label,
													scientific
												}, index)
											}
										>
											{showLeafImages ? (
												{image}.image()
											) : (
												<strong>{label}</strong>
											)}
										</button>
									)
								}
							)
						}
					</div>
					:
					null
				}
			</Col>
		</Row>
	)
};
