import React, {useState} from "react";

import {GeoJSON, WKT} from "ol/format";
import {Vector} from "ol/layer";
import {
	Stroke,
	Style
} from "ol/style";
import {fromLonLat} from "ol/proj";
import VectorSource from "ol/source/Vector";

import {DisadvantagedMap} from "./DisadvantagedMap";
import {PPIMap} from "./PPIMap";
import {Top} from "./Top";
import {HeavyLine} from "../NutritionLabelParts";

import {getValueFromSearchParams} from "../../../utilities/utilities";
import {DEVMODE} from "../../../utilities/resources";
import {GEOSERVER_URL} from "../../static_props/OurTrees";

import "../../../stylesheets/ourtrees.scss";
import {ProcessingModal} from "../Modals";


export const ReportMaps = (
	{
		address,
		canvasTarget,
		layer,
	}
) => {
	let [processingModalIsOpen, setProcessingModalIsOpen] = useState(false);
	let wktGeometry;

	let createURL = (layerName, cqlFilter) => {
		return new URL(
			`${GEOSERVER_URL}?${createURLSearchParams(layerName, cqlFilter).toString()}`
		);
	};
	let createURLSearchParams = (layerName, cqlFilter) => {
		return new URLSearchParams(
			{
				service: "WFS",
				version: "2.0.0",
				request: "GetFeature",
				typeName: layerName,
				srsName: "EPSG:3857",
				outputFormat: "application/json",
				cql_filter: cqlFilter,
			}
		);
	};

	const HEADERS = {
		"Accept": "application/json",
		"Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
	};
	const METHOD = "POST";
	let fetchFeatures = async (layer) => {
		setProcessingModalIsOpen(true);
		let cqlFilter = `INTERSECTS(geom, ${wktGeometry})`;
		let searchParams = createURLSearchParams(layer, cqlFilter);
		let response = await fetch(
			GEOSERVER_URL,
			{
				body: searchParams,
				headers: HEADERS,
				method: METHOD,
			}
		);

		if (response.ok) {
			let featureCollection = await response.json();

			setProcessingModalIsOpen(false);

			if (
				featureCollection?.totalFeatures &&
				featureCollection?.totalFeatures >= 1
			) {
				return featureCollection.features
			} else {
				throw new Error(
					"No intersecting disadvantaged block groups could be found."
				);
			}
		} else {
			throw new Error(
				`Status ${response.statusCode}. No features could be fetched.`
			);
		}
	};

	let center = fromLonLat([
		parseFloat(getValueFromSearchParams("longitude")),
		parseFloat(getValueFromSearchParams("latitude")),
	]);
	let originalFeatureURL = createURL(
		`landscape:${layer}`,
		`INTERSECTS(geom, POINT(${center[0]} ${center[1]}))`
	);
	let originalFeatureLayer = new Vector({
		title: "originalFeature",
		source: new VectorSource({
			url: originalFeatureURL.href,
			format: new GeoJSON(),
		}),
		style: new Style({
			stroke: new Stroke({
				color: "#000",
				width: 2,
			}),
		}),
	});

	originalFeatureLayer.getSource().once("featuresloadend", () => {
		let wicket = new WKT();
		wktGeometry = wicket.writeGeometry(
			originalFeatureLayer.getSource().getFeatures()[0].getGeometry()
		);
	});

	return (
		<section  id={canvasTarget}>
			<Top text={"OurTrees Maps"}/>
			<DisadvantagedMap
				address={address}
				originalFeatureLayer={originalFeatureLayer}
				fetchFeatures={fetchFeatures}
			/>
			<HeavyLine/>
			<PPIMap
				address={address}
				originalFeatureLayer={originalFeatureLayer}
				fetchFeatures={fetchFeatures}
			/>
			<ProcessingModal
				text={"Retrieving equity data..."}
				isOpen={processingModalIsOpen}
			/>
		</section>
	);
};
