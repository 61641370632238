import React, {useEffect, useState, useRef} from "react";
import {DEVMODE} from "../../utilities/resources";
import {StorageHandler} from "../../classes/storage";

import "../../stylesheets/species-selector.scss";


export const SpeciesSelector = (
	{
		tree,
		namingType,
		updateProject,
	} = {}
) => {
	let name = namingType ? "common" : "scientific";
	let [speciesSelection, setSpeciesSelection] = useState(tree[name]);
	let [focus, setFocus] = useState(false);
	let [processing, setProcessing] = useState(true);
	let [masterList, setMasterList] = useState({});
	let [taxonomies, setTaxonomies] = useState({
		classes: [],
		genera: [],
		species: [],
	});
	let [options, setOptions] = useState([]);

	let inputRef = useRef();
	let optionContainerRef = useRef();
	let leafButtonContainerRef = useRef();

	const handleClickOutside = (event) => {
		if (
			!(
				(
					inputRef.current &&
					inputRef.current.contains(event.target)
				) || (
					optionContainerRef.current &&
					optionContainerRef.current.contains(event.target)
				) || (
					leafButtonContainerRef.current &&
					leafButtonContainerRef.current.contains(event.target)
				)
			)
		) {
			setFocus(false);
		}
	};

	useEffect(
		() => {
			let speciesListStorageKey = "i-Tree_Species_List";
			let storageHandler = new StorageHandler({key: speciesListStorageKey});
			let storageAvailable = storageHandler.checkForStorage();

			leafButtonContainerRef.current = document.querySelector(".leaf-button-container");

			// The event listener here because we have to wait for the refs to be set.
			document.addEventListener(
				"click",
				handleClickOutside,
				true,
			);

			document.querySelector(".feedback").classList.add("d-none");
			inputRef.current.classList.remove("is-invalid");

			// Try to retrieve the species list from local storage.
			let storedValues = {};
			// Update every day plus 1 millisecond
			let update = 86400001;
			let now = new Date().getTime();
			let difference = now - storedValues.date;

			if (storageAvailable) {
				storedValues = storageHandler.getFromStorage();
				if (Object.keys(storedValues).length) {
					setMasterList(storedValues.list);
				}
			}
			if (
				!storageAvailable ||
				!Object.keys(storedValues).length ||
				(difference >= update)
			) {
				// Fetch the species list from the Engine if it
				// can't be retrieved from storage, storage is
				// unavailable, or the stored version is out
				// of date.
				getSpeciesData().then((json) => {
					let newList = createList(json);
					if (storageAvailable) {
						storageHandler.putInStorage(
							{
								list: newList,
								date: now,
							}
						);
					}
					setMasterList(newList);
				});
			}
		},
		[]
	);

	useEffect(
		() => {
			if (Object.keys(masterList).length) {
				const sortListByTaxonomy = () => {
					let species = [];
					let genera = [];
					let classes = [];

					for (let item in masterList) {
						let entry = {
							spp: masterList[item]["spp"],
							common: masterList[item]["common"],
							scientific: masterList[item]["scientific"],
							rank: masterList[item]["rank"],
						};
						switch (masterList[item]["rank"]) {
							case "class":
								classes.push(entry);
								break;
							case "genus":
								genera.push(entry);
								break;
							case "species":
								species.push(entry);
								break;
							default:
								throw new Error(
									`A problem has occurred while sorting species by taxonomy.`
								);
						}
					}
					return {
						classes: classes.sort(compareValues(name)),
						genera: genera.sort(compareValues(name)),
						species: species.sort(compareValues(name)),
					};
				};
				let listSortedIntoTaxonomies = sortListByTaxonomy(masterList);
				setTaxonomies(listSortedIntoTaxonomies);
				setProcessing(false);
			}
		},
		[masterList, name]
	);

	useEffect(
		() => {
			setSpeciesSelection(tree[name]);
		},
		[tree, name]
	);

	const getSpeciesData = async () => {
		let key = process.env.REACT_APP_ITREE_ENGINE_API;
		let url = `https://${DEVMODE ? "dev." : ""}api.itreetools.org/v2/getSpecies/?key=${key}&output=JSON`;

		let response = await fetch(url);
		if (response.ok) {
			return await response.json();
		} else {
			throw new Error("There has been an error creating the species list.");
		}
	};

	const createList = (json) => {
		let columns = {};
		let list = {};
		for (let i = 0; i < json.meta.length; i++) {
			columns[json.meta[i]["name"]] = i;
		}
		json.data.map(item => list[item[columns.Code].toUpperCase()] = {
			spp: item[columns.Code].toUpperCase(),
			scientific: item[columns.ScientificName],
			common: item[columns.CommonName],
			rank: item[columns.SpeciesType].toLowerCase() // taxonomic rank.
		});
		return list;
	};

	const compareValues = (key, order = "asc") => {
		return (a, b) => {
			let comparison = 0;
			let first = a[key];
			let second = b[key];
			if (typeof first === "string") first = first.toLowerCase();
			if (typeof second === "string") second = second.toLowerCase();

			if (first > second) {
				comparison = 1;
			} else if (first < second) {
				comparison = -1;
			}

			return (
				(order === "desc") ? (comparison * -1) : comparison
			);
		}
	};

	const onChange = () => {
		inputRef.current.classList.remove("is-invalid");
		let feedbackElementClassList = document.querySelector(".feedback").classList;
		feedbackElementClassList.add("d-none");
		optionContainerRef.current.classList.remove("no-height");

		setFocus(true);

		let inputValue = inputRef.current.value;
		setSpeciesSelection(inputValue);
		let filteredTaxonomies = {
			classes: [],
			genera: [],
			species: [],
		};

		if (inputValue.length >= 3) {
			for (let taxonomy in taxonomies) {
				filteredTaxonomies[taxonomy] = taxonomies[taxonomy].filter(
					(entry) => entry[name].toLowerCase().includes(inputValue.toLowerCase())
				);
			}

			if (
				filteredTaxonomies.classes.length <= 0 &&
				filteredTaxonomies.genera.length <= 0 &&
				filteredTaxonomies.species.length <= 0
			) {
				inputRef.current.classList.add("is-invalid");
				feedbackElementClassList.remove("d-none");
				optionContainerRef.current.classList.add("no-height");
			}
		} else {
			if (inputValue.length === 0) {
				updateProject(
					{
						tree: {
							species: "",
							common: "",
							scientific: "",
						}
					}
				);
			}
			optionContainerRef.current.classList.add("no-height");
		}
		createOptions(filteredTaxonomies);
	};

	const onClick = (event) => {
		let {code, common, scientific} = event.currentTarget.dataset;
		inputRef.current.value = namingType ? common : scientific;
		optionContainerRef.current.classList.add("no-height");
		setFocus(false);
		updateProject(
			{
				tree: {
					species: code,
					common: common,
					scientific: scientific,
				}
			}
		);
	};

	const createOptions = (filteredTaxonomies) => {
		let options = [];
		for (let taxonomy in filteredTaxonomies) {
			let classifications = filteredTaxonomies[taxonomy];
			classifications.forEach((classification, index) => {
				options.push(
					<div
						key={classification.spp}
						data-code={classification.spp}
						data-common={classification.common}
						data-scientific={classification.scientific}
						onClick={onClick}
					>
						<p className={"m-0 p-0"}>
							{classification[namingType ? "common" : "scientific"]}
						</p>
						<small className={"fst-italic"}>
							{classification[namingType ? "scientific" : "common"]}
						</small>
					</div>);
			});
		}

		setOptions(options);
	};

	let placeholder = processing ? "Retrieving species list" : (namingType ? 'Try: "Oak" or "Maple"' : 'Try: "Quercus" or "Acer"');

	return (
		<React.Fragment>
			<div id={"species-select-container"}>
				<input
					id={"species-input"}
					className={"form-control species-input"}
					placeholder={placeholder}
					ref={inputRef}
					onChange={onChange}
					value={speciesSelection}
					onFocus={
						(event) => {
							setFocus(true);
							event.target.select();
						}
					}
				/>
				<div
					ref={optionContainerRef}
					className={`option-container${focus ? "" : " no-height"}`}
				>
					{options.map((option, index) => {
						return option
					})}
				</div>
				<small className={"d-none text-danger fst-italic feedback"}>
					A valid species must be selected from the list.
				</small>
			</div>
		</React.Fragment>
	)
};
