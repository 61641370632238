import {Benefits} from "./benefits";
import {Building} from "./building";
import {Location} from "./location";
import {Tree} from "./tree";
import {HTHC} from "./hthc";

import {v4 as createUUID} from 'uuid';


export class Project {
	constructor(
		{
			uuid = createUUID(),
			error = "",
			warning = "",
			editing = false,
			group = "",
			note = "",
			creationDate = Date.now(),
			location = new Location(),
			tree = new Tree(),
			building = new Building(),
			benefits = new Benefits(),
			futureBenefits = new Benefits(),
			hthc = new HTHC(),
		} = {}
	) {
		this.uuid = uuid;
		this.error = error;
		this.warning = warning;
		this.editing = editing;
		this.group = group;
		this.note = note;
		this.creationDate = creationDate;
		this.location = new Location(location);
		this.tree = new Tree(tree);
		this.building = new Building(building);
		this.benefits = new Benefits(benefits);
		this.futureBenefits = new Benefits(futureBenefits);
		this.hthc = new HTHC(hthc);
	}

	__fields = () => {
		return {
			uuid: this.uuid,
			error: this.error,
			warning: this.warning,
			editing: this.editing,
			group: this.group,
			note: this.note,
			creationDate: this.creationDate,
			location: this.location.__fields(),
			tree: this.tree.__fields(),
			building: this.building.__fields(),
			benefits: this.benefits.__fields(),
			futureBenefits: this.futureBenefits.__fields(),
			hthc: this.hthc.__fields(),
		};
	};

	__url = (emailed = false) => {
		let fields = {
			error: this.error,
			warning: this.warning,
			creationDate: this.creationDate,
			group: this.group,
			note: this.note,
		};
		let query = new URLSearchParams();

		function cycleFields(fields) {
			for (let f in fields) {
				if (fields[f] instanceof Object) {
					cycleFields(fields[f])
				} else {
					query.append(f, fields[f]);
				}
			}
		}

		cycleFields(fields);

		return `${query}/${this.location.__url()}/${this.tree.__url()}/${this.building.__url()}/${this.hthc.__url()}`;
	};

	checkIfEmailed = (url) => {
		url = url.slice(10, url.length);
		let searchParams = new URLSearchParams(url);
		return searchParams.has("emailed");
	}

	readURL = (url) => {
		url = url.slice(10, url.length);
		let searchParams = new URLSearchParams(url);

		for (let pair of searchParams.entries()) {
			let key = pair[0];
			let value = pair[1];
			if (value.includes("uuid")) value = value.split("/")[0];

			if (this.hasOwnProperty(key)) {
				this[key] = value;
			} else if (this["location"].hasOwnProperty(key)) {
				this["location"][key] = value;
			} else if (this["location"]["address"].hasOwnProperty(key)) {
				this["location"]["address"][key] = value;
			} else if (this["tree"].hasOwnProperty(key)) {
				this["tree"][key] = value;
			} else if (this["building"].hasOwnProperty(key)) {
				// FIXME: Proximity must be an Integer for various conditionals.
				if (key === "proximity") value = parseInt(value);
				this["building"][key] = value;
			} else if (this["hthc"].hasOwnProperty(key)) {
				this["hthc"][key] = value;
			}
		}
	};

	__flatten = () => {
		let flattened = {};
		let fields = this.__fields();

		function cycleFields(fields) {
			for (let f in fields) {
				if (f !== "uuid") {
					if (fields[f] instanceof Object) {
						cycleFields(fields[f])
					} else {
						flattened[f] = fields[f];
					}
				}
			}
		}

		cycleFields(fields);

		flattened.project_uuid = this.uuid;
		flattened.location_uuid = this.location.uuid;
		flattened.tree_uuid = this.tree.uuid;
		flattened.building_uuid = this.building.uuid;
		flattened.hthc_uuid = this.hthc.uuid;

		return flattened
	};

	__rebuild = (data) => {
		this.uuid = data.project_uuid;
		this.location.uuid = data.location_uuid;
		this.tree.uuid = data.tree_uuid;
		this.building.uuid = data.building_uuid;
		this.hthc.uuid = data.hthc_uuid;

		for (let d in data) {
			if (this.hasOwnProperty(d)) {
				// FIXME: Conditional to handle Papaparse
				//  replacing empty strings with null
				this[d] = (d === "error" && data[d] === null) ||
				(d === "warning" && data[d] === null) ? "" : data[d];
			} else if (this.location.hasOwnProperty(d)) {
				this.location[d] = data[d];
			} else if (this.location.address.hasOwnProperty(d)) {
				this.location.address[d] = data[d];
			} else if (this.tree.hasOwnProperty(d)) {
				this.tree[d] = ["condition", "exposure"].includes(d) ? data[d].toString() : data[d];
			} else if (this.building.hasOwnProperty(d)) {
				this.building[d] = ["proximity"].includes(d) ? data[d] : data[d].toString();
			} else if (this.hthc.hasOwnProperty(d)) {
				this.hthc[d] = typeof data[d] === "boolean" ? data[d] === true : data[d].toString();
			} else if (this.benefits.HydroBenefit.hasOwnProperty(d)) {
				this.benefits.HydroBenefit[d] = data[d]
			} else if (this.benefits.EnergyBenefit.hasOwnProperty(d)) {
				this.benefits.EnergyBenefit[d] = data[d];
			} else if (this.benefits.AirQualityBenefit.hasOwnProperty(d)) {
				this.benefits.AirQualityBenefit[d] = data[d];
			} else if (this.benefits.CO2Benefit.hasOwnProperty(d)) {
				this.benefits.CO2Benefit[d] = data[d];
			} else if (this.benefits.Carbon.hasOwnProperty(d)) {
				this.benefits.Carbon[d] = data[d];
			}
		}
	};

	validate = () => {
		let u = "undefined";
		let response = {
			proceed: true,
			errors: {
				uuid: false,
				editing: false,
				creationDate: false,
			}
		};

		if (
			!this.uuid ||
			typeof this.uuid === u ||
			this.uuid === ""
		) {
			response.proceed = false;
			response.errors.uuid = true;
		} else {
			delete response.errors.uuid;
		}

		if (
			typeof this.editing === u ||
			(!this.editing instanceof Boolean)
		) {
			response.proceed = false;
			response.errors.editing = true;
		} else {
			delete response.errors.editing;
		}

		if (
			typeof this.creationDate === u ||
			this.creationDate === ""
		) {
			response.proceed = false;
			response.errors.creationDate = true
		} else {
			delete response.errors.creationDate;
		}

		// let location = this.location.validate(); FIXME: Still trying to find a solution to adding cities/states back into the data structure.
		let tree = this.tree.validate();
		let building = this.building.validate();

		 // FIXME: Still trying to find a solution to adding cities/states back into the data structure.
		// if (!location.proceed) {
		// 	response.proceed = false;
		// 	for (let l in location.errors) {
		// 		response.errors[l] = true;
		// 	}
		// }

		if (!tree.proceed) {
			response.proceed = false;
			for (let t in tree.errors) {
				for (let f in tree[t]) {
					response.errors[f] = true;
				}
			}
		}

		if (!building.proceed) {
			response.proceed = false;
			for (let b in building.errors) {
				response.errors[b] = true;
			}
		}

		return response;
	};
}
