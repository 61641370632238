import React, {useState} from 'react';
import {
	Collapse,
	Container,
	Form,
} from "reactstrap";

import * as Tree from "../parts/TreeFields";
import * as Building from "../parts/BuildingFields";
import {
	PageTitle,
	RequiredFields
} from "../parts/GeneralPageElements";
import {OptionalFieldsAccordion} from "../parts/OptionalFieldsAccordion";


export const TreeForm = (
	{
		history,
		project,
		saveProject,
		unitType,
		updateProject
	}
) => {
	let [processing, setProcessing] = useState(false);

	const validateInputs = (e, path, submitToEngine = true) => {
		e.preventDefault();

		let invalid = "is-invalid";
		let feedback = ".invalid-feedback";
		let displayBlock = "d-block";
		let proceed = true;
		let inputs = {
			species: document.getElementById("species-input"),
			condition: document.getElementById("condition"),
			diameter: document.getElementById("diameter"),
			exposure: document.getElementById("exposure"),
			vintage: null,
			direction: null,
			distance: null,
		};
		let scroll = null;

		if (inputs.species.value === "") {
			inputs.species.classList.add(invalid);
			document.querySelector(`#species-form ${feedback}`).classList.add(displayBlock);
			proceed = false;
			if (!scroll) scroll = inputs.species.labels[0];
		}

		if (
			inputs.condition.value === "" ||
			inputs.condition.value < 0 ||
			isNaN(inputs.condition.value)
		) {
			inputs.condition.classList.add(invalid);
			proceed = false;
			if (!scroll) scroll = inputs.condition;
		}

		if (
			inputs.diameter.value === "" ||
			inputs.diameter.value < 1 ||
			inputs.diameter.value > 999 ||
			isNaN(inputs.diameter.value)
		) {
			inputs.diameter.classList.add(invalid);
			proceed = false;
			if (!scroll) scroll = inputs.diameter.labels[0];
		}

		if (project.building.proximity === 0) {
			inputs.vintage = document.getElementById("vintage");
			inputs.direction = document.getElementById("direction");
			inputs.distance = document.getElementById("distance");

			if (
				typeof inputs.vintage.value === "undefined" ||
				inputs.vintage.value === "" ||
				inputs.vintage.value < 0
			) {
				inputs.vintage.classList.add(invalid);
				proceed = false;
				if (!scroll) scroll = inputs.vintage.labels[0];
			}

			if (
				typeof inputs.direction.value === "undefined" ||
				inputs.direction.value === "" ||
				inputs.direction.value < 0
			) {
				inputs.direction.classList.add(invalid);
				proceed = false;
				if (!scroll) scroll = inputs.direction.labels[0];
			}

			if (
				typeof inputs.distance.value === "undefined" ||
				inputs.distance.value === "" ||
				inputs.distance.value < 0
			) {
				inputs.distance.classList.add(invalid);
				proceed = false;
				if (!scroll) scroll = inputs.distance.labels[0];
			}
		}

		if (proceed) {
			let status = project.validate();
			if (!status.proceed) {
				for (let e in status.errors) {
					if (inputs[e]) inputs[e].classList.add(invalid);
				}
				proceed = false;
			}
		}

		if (proceed) {
			setProcessing(true);
			saveProject(history, path, submitToEngine);
		} else {
			if (scroll) scroll.scrollIntoView();
		}
	};

	let text = <span>Add more trees or get results</span>;
	let countriesWhereWeShowTheSpeciesShortcut = ["us", "usa", "united states", "u.s.", "canada", "ca"];
	let showSpeciesShortcuts = countriesWhereWeShowTheSpeciesShortcut.includes(project.location.nation.toLowerCase());

	return (
		<Form>
			<Container>
				<PageTitle
					text={"Tell us about your tree:"}
				/>
				<Tree.AddressField
					address={project.location.address.entered}
					latitude={project.location.latitude}
					longitude={project.location.longitude}
				/>
				<Tree.SpeciesShortCut
					tree={project.tree}
					showSpeciesShortcuts={showSpeciesShortcuts}
					updateProject={updateProject}
				/>
				<Tree.SpeciesField
					tree={project.tree}
					updateProject={updateProject}
				/>
				<Tree.ConditionField
					condition={project.tree.condition}
					updateProject={updateProject}
				/>
				<Tree.DiameterField
					diameter={project.tree.diameter}
					measurementType={project.tree.measurementType}
					unitType={unitType}
					updateProject={updateProject}
				/>
				<Tree.ExposureField
					exposure={project.tree.exposure}
					updateProject={updateProject}
				/>
				<Building.Proximity
					unitType={unitType}
					proximity={project.building.proximity}
					updateProject={updateProject}
				/>
				<Collapse isOpen={project.building.proximity === 0}>
					<Building.Vintage
						vintage={project.building.vintage}
						updateProject={updateProject}
					/>
					<Building.Distance
						unitType={unitType}
						distance={project.building.distance}
						updateProject={updateProject}
					/>
					<Building.Direction
						direction={project.building.direction}
						updateProject={updateProject}
					/>
				</Collapse>
				<RequiredFields/>
				<Tree.SaveButton
					text={text}
					disabled={processing}
					validateInputs={validateInputs}
				/>
				<OptionalFieldsAccordion
					disabled={processing}
					group={project.group}
					note={project.note}
					optIn={project.hthc.optIn}
					text={text}
					trillionTrees={project.tree.trillionTrees}
					type={project.tree.type}
					validateInputs={validateInputs}
					updateProject={updateProject}
				/>
			</Container>
		</Form>
	)
}
